export const skillRoleObject = {
  '.NET': 'Developers',
  'Ableton Live': 'Music Producers',
  Abridged: 'Copywriters',
  Acapella: 'Vocalists',
  'ACID Pro': 'Music Producers',
  'Active Directory': 'Developers',
  Adalo: 'Developers',
  ADK: 'Designers',
  'Adobe After Effects': 'Motion Graphic Designers',
  'Adobe Animate': '2D Animators',
  'Adobe Audition': 'Sound Editors',
  'Adobe Character Animator': '2D Animators',
  'Adobe ColdFusion': 'Web Developers',
  'Adobe Dimenson': 'Designers',
  'Adobe Dreamweaver': 'Web Designers',
  'Adobe FreeHand': 'Illustrators',
  'Adobe Illustrator': 'Designers',
  'Adobe InDesign': 'Graphic Designers',
  'Adobe Lightroom': 'Photographers',
  'Adobe Photoshop': 'Graphic Designers',
  'Adobe Premiere Elements': 'Video Editors',
  'Adobe Premiere Pro': 'Video Editors',
  'Adobe Spark': 'Digital Marketers',
  'Adobe XD': 'UI Designers',
  'Affinity Designer': 'Illustrators',
  'AI Chatbot': 'Developers',
  Airtable: 'Developers',
  Akeneo: 'Developers',
  'Amazon Web Services': 'Cloud Developers',
  Android: 'App Developers',
  AngularJS: 'Web Developers',
  Anime: 'Animators',
  API: 'Developers',
  AppDrag: 'Developers',
  AppGyver: 'App Developers',
  AppSheet: 'App Developers',
  'ArtRage 5': 'Artists',
  'ASP.NET': 'Developers',
  Augmania: 'Digital Marketers',
  Aurelia: 'Front-End Developers',
  'Autodesk Flame': 'Visual Effects Artists',
  'Autodesk Maya': '3D Animators',
  'Autodesk SketchBook': 'Illustrators',
  AWS: 'Cloud Developers',
  Axiom: 'Developers',
  Axure: 'Designers',
  Azure: 'Cloud Developers',
  'Backing Vocal': 'Musicians',
  Banner: 'Graphic Designers',
  'Betty Blocks': 'App Developers',
  Bitcoin: 'Blockchain Developers',
  Blender: 'Animators',
  'Blue Prism': 'Developers',
  Blueprints: 'Artists',
  BoonEx: 'Developers',
  Bootstrap: 'Web Developers',
  'Bravo Studio': 'Developers',
  Bubble: 'App Developers',
  Buildbox: 'Game Developers',
  Builder: 'App Developers',
  BuilderX: 'Developers',
  C: 'Developers',
  'C#': 'Developers',
  'C++': 'Developers',
  CakePHP: 'Web Developers',
  'Cakewalk Sonar': 'Music Producers',
  Camtasia: 'Video Editors',
  Canva: 'Graphic Designers',
  Carrd: 'Developers',
  Cartoon: '2D Animators',
  Caspio: 'Developers',
  Cassandra: 'Database Developers',
  'CelAction Animatic': 'Animators',
  CelAction2D: 'Animators',
  'Chrome Extension': 'Developers',
  Citrix: 'Data Visualizers',
  Clappia: 'App Developers',
  Claymation: 'Animators',
  CodeIgniter: 'Web Developers',
  'Corel Draw': 'Graphic Designers',
  CorelDraw: 'Graphic Designers',
  'Corporate Video': 'Content Producers',
  CRM: 'Digital Marketers',
  CSS: 'Web Developers',
  'Cyberlink PowerDirector': 'Video Editors',
  Dart: 'Developers',
  'DaVinci Resolve Studio': 'Video Editors',
  DB2: 'Database Developers',
  DevExpress: 'Developers',
  Django: 'Web Developers',
  Docker: 'DevOps Developers',
  Draftbit: 'Developers',
  DreamFactory: 'Developers',
  DronaHQ: 'Developers',
  Drupal: 'CMS Developers',
  DynamoDB: 'Database Developers',
  Edius: 'Video Editors',
  Elasticsearch: 'Developers',
  Elementor: 'Web Developers',
  'Email Marketing': 'Digital Marketers',
  'Ember.js': 'Web Developers',
  Erlang: 'Developers',
  Ethereum: 'Blockchain Developers',
  ETL: 'Database Developers',
  'Explainer Video': 'Content Producers',
  Express: 'Web Developers',
  'Facebook Ads': 'Digital Marketers',
  'Facebook API': 'Developers',
  Facebook: 'Social Media Freelancers',
  'Fashion Illustration': 'Artists',
  Figma: 'Designers',
  'Final Cut Pro X': 'Video Editors',
  Finale: 'Music Composers',
  Fivetran: 'Database Developers',
  'FL Studio': 'Music Producers',
  Flutter: 'Developers',
  Framer: 'Developers',
  GarageBand: 'Music Composers',
  GIF: 'Animators',
  GIMP: 'Graphic Designers',
  Go: 'Developers',
  'Google Ads': 'Digital Marketers',
  'Google API': 'Developers',
  'Google Cloud': 'Cloud Developers',
  'Google Maps': 'Developers',
  'Gouache Illustration': 'Artists',
  Grails: 'Developers',
  Grapedrop: 'Web Designers',
  GraphQL: 'Developers',
  Hadoop: 'Developers',
  HTML5: 'Web Developers',
  HubSpot: 'Digital Marketers',
  Icon: 'Graphic Designers',
  iMovie: 'Video Editors',
  inkscape: 'Illustrators',
  'Instagram API': 'Developers',
  Instagram: 'Social Media Freelancers',
  Invision: 'Graphic Designers',
  ionic: 'Developers',
  iOS: 'App Developers',
  Java: 'Developers',
  JavaScript: 'Web Developers',
  Jimdo: 'Web Developers',
  Jira: 'Developers',
  jQuery: 'Web Developers',
  Keras: 'AI Engineers',
  Kotlin: 'Developers',
  Kubernetes: 'DevOps Developers',
  Laravel: 'Web Developers',
  'Lead Nurturing': 'Digital Marketers',
  LinkedIn: 'Social Media Freelancers',
  LMS: 'Developers',
  'Logic Pro': 'Music Producers',
  Lua: 'Developers',
  Magento: 'Web Developers',
  Mailchimp: 'Digital Marketers',
  MariaDB: 'Database Developers',
  'MAXON Cinema 4D': 'Animators',
  'Mean Stack': 'Developers',
  'Microsoft Excel': 'Developers',
  'Microsoft Powerpoint': 'Graphic Designers',
  'Microsoft SQL': 'Database Developers',
  MikuMikuDance: '3D Animators',
  Mixamo: '3D Animators',
  Monday: 'Developers',
  MongoDB: 'Database Developers',
  'Music Video': 'Video Editors',
  MySQL: 'Database Developers',
  Neo4j: 'Database Developers',
  'Node.js': 'Web Developers',
  'Objective-C': 'Developers',
  OpenStack: 'Developers',
  Oracle: 'Database Developers',
  Outfunnel: 'Digital Marketers',
  PayPal: 'Developers',
  'Pen and Ink Illustration': 'Freelance Digital Illustrators',
  'Pencil Illustration': 'Freelance Digital Illustrators',
  Pencil2D: '2D Animators',
  PHP: 'Web Developers',
  phpBB: 'Web Developers',
  Pinterest: 'Social Media Freelancers',
  'Pitch Deck': 'Graphic Designers',
  'PL SQL': 'Database Developers',
  Portrait: 'Photographers',
  POS: 'Developers',
  PostgreSQL: 'Database Developers',
  'PreSonus Studio One': 'Music Producers',
  'Pro Tools': 'Music Producers',
  Procreate: 'Designers',
  'Product Video': 'Content Creators',
  'Promo Video': 'Content Creators',
  'Propellerhead Reason': 'Music Producers',
  Python: 'Developers',
  PyTorch: 'AI Engineers',
  Qt: 'Developers',
  Quora: 'Social Media Freelancers',
  R: 'Developers',
  'Raspberry Pi': 'Developers',
  'React JS': 'Web Developers',
  'React Native': 'Developers',
  REAKTOR: 'Music Producers',
  'Reallusion Cartoon': '2D Animators',
  'Reallusion Character Creator': '3D Animators',
  'Reallusion iClone': '3D Animators',
  Reddit: 'Social Media Freelancers',
  Redis: 'Database Developers',
  Retool: 'Developers',
  Rotoscope: 'Animators',
  'Ruby on Rails': 'Web Developers',
  Ruby: 'Web Developers',
  Rust: 'Developers',
  SaaS: 'Developers',
  'Sails.js': 'Web Developers',
  Salesforce: 'Developers',
  Scala: 'Developers',
  Selenium: 'QA Engineers',
  'SGO Mistika': 'Video Editors',
  Shopify: 'Developers',
  'Short Film': 'Content Producers',
  Sibelius: 'Music Composers',
  Sketch: 'Designers',
  'Slack Workflow': 'Developers',
  Smarty: 'Developers',
  Snapchat: 'Social Media Freelancers',
  Snowflake: 'Developers',
  'Socket.IO': 'Developers',
  Solidity: 'Blockchain Developers',
  Spring: 'Developers',
  SQL: 'Database Developers',
  SQLite: 'Database Developers',
  Squarespace: 'Web Developers',
  'Steinberg Cubase': 'Music Producers',
  'Steinberg Nuendo': 'Sound Editors',
  'Steinberg Wavelab': 'Mastering Engineers',
  'Stop Action': 'Photographers',
  'Stop Motion': 'Photographers',
  Stripe: 'Developers',
  Swift: 'Developers',
  Symfony: 'Web Developers',
  Tailwind: 'Front-End Developers',
  TensorFlow: 'AI Engineers',
  Tiktok: 'Social Media Freelancers',
  Titanium: 'Developers',
  'Toon Boom Harmony': '2D Animators',
  'Toon Boom Producer': 'Creative Producers',
  'Toon Boom Storyboard Pro': 'Illustrators',
  Traktor: 'DJs',
  Tumblr: 'Digital Marketers',
  Twilio: 'Developers',
  Twinmotion: '3D Designers',
  Twitch: 'Content Creators',
  'Twitter Ads': 'Digital Marketers',
  'Twitter API': 'Developers',
  Twitter: 'Social Media Freelancers',
  TypeScript: 'Web Developers',
  'UI Path': 'Developers',
  'Unity 3D': 'Developers',
  Unity: 'Game Developers',
  'Unreal Engine 3': 'Game Developers',
  'Unreal Engine 4': 'Game Developers',
  'Vegas Pro': 'Video Editors',
  'Video Advert': 'Content Creators',
  VMware: 'DevOps Developers',
  Vocaloid: 'Music Producers',
  Voiceflow: 'Developers',
  'Vue.js': 'Web Developers',
  web2py: 'Developers',
  Webflow: 'Web Developers',
  WebKit: 'Developers',
  WeChat: 'Social Media Managers',
  Weebly: 'Developers',
  'Whiteboard Video': 'Animators',
  WiX: 'Web Developers',
  WordPress: 'Web Developers',
  Xamarin: 'Developers',
  XML: 'Developers',
  'Yelp API': 'Developers',
  'YouTube API': 'Developers',
  YouTube: 'Content Creators',
  Zapier: 'Developers',
  ZBrush: 'Designers',
  Zend: 'Web Developers',
};
